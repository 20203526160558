<template>
	<div>
		<div class="text-lg font-bold border-b">Company analysis</div>

		<div class="font-bold my-3 text-sm text-gray-500">
			Data entered here will NOT be saved after you leave the page.<br />
			Create a Company Analysis project to keep persistent results.
		</div>
		<CustomDomainAnalysisView />
	</div>
</template>

<script>
import Button from "@/components/ui/Button";
import CustomDomainAnalysisView from "@/components/projects/company-analysis/CustomDomainAnalysisView";
import { mapGetters } from "vuex";

const back_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>`;
const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>`;
const trash_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>`;

export default {
	created() {},

	mounted() {},

	components: {
		// 'v-button': Button,
		CustomDomainAnalysisView,
	},

	props: ["teamId"],

	data() {
		return {};
	},

	methods: {},

	watch: {
		resourceStatus(status) {},
		$route(to, from) {},
	},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>

<style></style>
